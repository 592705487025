<template>
  <div class="project-card margin-container-top">
    <div class="project-info">
      <div class="title dotdotdot font-size-big">{{ title }}</div>
      <div class="sub-title font-size-sm">{{ subTitle }}</div>
    </div>
    <div class="container" v-bind="$attrs">
      <img class="image" :src="image" />
      <div class="description hover">
        <div class="description-text font-size-md">
          {{ description }}
        </div>
        <div class="button-container">
          <router-link :to="'/projects/' + id">
            <button class="btn-project">
              <div class="btn-text font-size-md">
                <!-- {{ $t("pages.projects.hover.button") }} -->
                view more
              </div>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    title: String,
    subTitle: String,
    image: String,
    description: String,
    id: Number,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.project-card {
  display: flex;
  flex-direction: column;

  .project-info {
    text-align: start;
    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      text-overflow: ellipsis;
      color: map-get($font_colors, "main");
      font-weight: 700;
    }
    .sub-title {
      color: map-get($font_colors, "secondary");
      font-weight: 800;
    }
  }
  .container {
    position: relative;

    .description.hover {
      position: absolute;
      right: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 50px;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      .description-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        color: map-get($colors, "white");
      }
      .button-container {
        .btn-project {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          max-width: 100%;
          cursor: pointer;
          color: map-get($colors, "white");
          letter-spacing: 1.5px;
          background-color: map-get($font_colors, "blue");
          border: map-get($colors, "white");
          font-weight: 300;
          .btn-text {
            box-sizing: border-box;
            word-break: break-word;
            padding: 24px 70px;
            @media (max-width: 900px) {
              padding: 18px 50px;
            }
            @media (max-width: 300px) {
              padding: 10px 25px;
              font-size: 35%;
            }
          }
        }
      }
      display: none;
    }
    &:hover {
      .description.hover {
        display: flex;
        .description-text {
          word-break: break-word;
          max-width: 75%;
          @media (max-width: 300px) {
            max-width: 100%;
            font-size: 35%;
          }
        }
      }
      img {
        opacity: 0;
        transition: opacity 400ms;
      }
    }
    .image {
      max-width: 70%;
      object-fit: scale-down;
      padding: 70px 0;
      float: right;
      /* &:hover{
        object-fit: contain;
        transform: scale(1.03);
        text-align: center;
        overflow: hidden;
       
      } */
    }
    &.acqua-marine {
      background: transparent linear-gradient(147deg, #00c2e7 0%, #ebfcff 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &.ombre {
      background: transparent linear-gradient(
145deg, #d2e3fc 0%, #aeff 100%) 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &.gray {
      background: transparent linear-gradient(
148deg, #c1e8fd 40%, #d1e3fc 100%) 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }
  &.margin-seperator {
    margin-bottom: 75px;
  }
}
</style>