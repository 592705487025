<template>
  <div class="info">
    <div class="info-content padding-container">
      <div class="info-title font-size-lg">
        {{ $t("pages.home.info.info-title") }}
      </div>
      <div
        class="info-desc font-size-md"
        v-html="$t('pages.home.info.info-desc')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: transparent
    linear-gradient(
      180deg,
      map-get($colors, "primary") 0%,
      map-get($colors, "linear-gradient-second") 100%
    )
    0% 0% no-repeat padding-box;
  .info-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    .info-title {
      color: map-get($font_colors, "main");
      /* font-family: monospace; */
      font-weight: 600;
      padding: 40px 0px;
    }
    .info-desc {
      color: map-get($font_colors, "secondary");
      font-weight: 300;
      /* font-family: monospace; */
      /* font-family: fantasy; */
    }
  }
}
</style>