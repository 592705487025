<template>
  <GoogleMap
  api-key="AIzaSyBmzdWziK7UrDJaADId-nOErWwByhvTqDc"
  style="width: 50%; height: 200px;"
  :center="center"
  :zoom="15"
  >
    <Marker :options="{ position: center }" />
  </GoogleMap>
</template>

<script>
import { defineComponent } from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'

export default defineComponent({
  components: { GoogleMap, Marker },
  setup() {
    const center = { lat: 41.32561794879982, lng: 19.81456708271217 }

    return { center }
  },
})
</script>
