<template>
<Header />
<router-view/>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  components: {
    Header: Header,

  },

  data() {
    return { };
  },

};
</script>

<style lang="scss">
@import "./scss/app.scss";
</style>
