<template>
  <div class="bg-content">
    <div class="image"></div>
    <div v-bind="$attrs" class="container">
      <div class="background-container">
        <div class="bg-circle"></div>
      </div>
    </div>
    <div class="content">
      <div class="text-wrapper">
        <div class="text-container">
          <div class="title font-size-xxl" :class="color">{{ title }}</div>
          <div class="text font-size-md">{{ text }}</div>
        </div>
      </div>
      <!-- <button class="btn">Get a quote</button> -->
        <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Background",
  props: ["title", "text", "color"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.bg-content {
  position: relative;
  min-height: 100vh;
  /* top: -30px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 900px) {
    justify-content: flex-start;
  }
  .container {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
    @media (max-width: 900px) {
      width: 100%;
    }
    height: 100%;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &.left {
      left: 0;
      .background-container {
        left: 0;
        @media (max-width: 900px) {
          left: auto;
        }
        .bg-circle {
          left: 0;
          @media (max-width: 900px) {
            left: auto;
          }
        }
      }
      @media (max-width: 900px) {
        left: auto;
      }
    }
    .background-container {
      box-sizing: border-box;
      position: absolute;
      width: 80%;
      padding-top: 56.25%;
      @media (max-width: 900px) {
        padding-top: 100%; 
        right: 0;
      }
      .bg-circle {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 70px;
        @media (max-width: 900px) {
          width: 100%;
          right: 0;
        }
        width: 75%;
        height: 100%;
        border-radius: 50%;
        background: transparent
          linear-gradient(
            180deg,
            map-get($colors, "linear-gradient-first") 0%,
            map-get($colors, "linear-gradient-second") 60%
          )
          0% 0% no-repeat padding-box;
        opacity: 0.7;
        filter: blur(50px);
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    .text-wrapper {
      /* padding-top: 150px; */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      @media (max-width: 900px) {
        flex-grow: unset;
      } 
      .text-container {
         /* position: absolute; */
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         /* top: 0; */
        @media (max-width: 900px) {
          word-break: break-word;
        }
        z-index: 3;
        width: 100%;
        top: 20%;
        @media (max-width: 900px) {
          width: 100%;
        }
        .title {
          padding: 30px;
          font-weight: 500;
          line-height: 1.2;
          color: map-get($font_colors, "main");
          &.blue {
            @media (max-width: 900px) {
              color: map-get($font_colors, "blue");
            }
          }
        }
        .text {
          width: 80%;
          padding: 30px;
          color: map-get($font_colors, "secondary");
          font-weight: 700;
        }
      }
    }
    /* .btn{
      width: 280px;
height: 93px;
background: #1052EF 0% 0% no-repeat padding-box;
text-align: center;
font-size: 26px;
letter-spacing: 0.52px;
color: #C1F3E4;
opacity: 1;
z-index: 3;
    } */
  }

  .image {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 90%;
    @media (max-width: 900px) {
      height: 50%;
      top: 25%;
    }
    background-image: url("../assets/white_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.85;
  }
}
</style>