<template>
  <div class="candidates">
    <background
      :title="$t('pages.candidates.Title')"
    >
      <div class="bottom-scroll">
        <ArrowDown />
      </div>
    </background>
  </div>
  <div class="candidates-text">
    <!-- <text-card>
      <template #title> {{ $t("pages.candidates.mid.title") }} </template>
      <template #subtitle>{{ $t("pages.candidates.mid.subtitle") }}</template>
    </text-card> -->
  </div>
  <footer-vue>
<card-form></card-form>
  </footer-vue>
</template>

<script>
import Background from "../components/Background.vue";
import ArrowDown from "../components/includes/views/ArrowDown.vue";
import FooterVue from "../components/FooterVue.vue";
import CardForm from '../components/includes/footer/footer-includes/CardForm.vue';

export default {
  name: "Candidates",
  components: {
    ArrowDown,
    FooterVue,
    Background,
    CardForm,
  },
  data() {
    return {};
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.bottom-scroll {
  z-index: 25;
  cursor: pointer;
  // padding-bottom: 50px;
}
.candidates-text {
  padding: 8% 0;
  @media (max-width: 900px) {
    padding: 0 10%;
    padding-bottom: 10%;
  }
}
</style>