<template>
  <div class="about">
    <background :title="$t('pages.aboutUs.title')">
        <div class="bottom-scroll">
        <ArrowDown />
      </div>
    </background>
    <div class="about-desc margin-container">
      <description></description>
    </div>
    <why-us></why-us>
    <footer-vue>
      <card class="margin-container"><home-card></home-card></card>
    </footer-vue>
  </div>
</template>

<script>
import Background from "../components/Background.vue";
import FooterVue from "../components/FooterVue.vue";
import Card from "../components/includes/footer/Card.vue";
import HomeCard from "../components/includes/footer/footer-includes/HomeCard.vue";
import Description from "../components/includes/pages/about/Description.vue";
import WhyUs from '../components/includes/pages/about/Why-Us.vue';
import ArrowDown from "../components/includes/views/ArrowDown.vue";

export default {
  name: "AboutUs",
  components: {
    Background,
    FooterVue,
    Card,
    HomeCard,
    Description,
    WhyUs,
    ArrowDown,
  },
    beforeMount() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss" scoped>
.about{
   .bottom-scroll {
    z-index: 25;
    cursor: pointer;
    // padding-bottom: 50px;
  }
}

</style>
