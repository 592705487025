<template>
  <div
    :class="{ onScroll: !view.topOfPage }"
    class="header padding-container font-size-sm"
  >
    <div class="header-component">
      <Logo />
    </div>
    <div class="header-component elements">
      <div class="router-link">
        <router-link class="router-hover scrollactive-item" to="/"
          >Home</router-link
        >
      </div>
      <div class="router-link">
        <router-link to="/about" class="router-hover scrollactive-item"
          >About Us</router-link
        >
      </div>
      <div
        class="router-link"
        @mouseover="listOne = true"
        @mouseleave="listOne = false"
      >
        <router-link to="/service" class="router-hover scrollactive-item"
          >Services</router-link
        >
        <div class="dropdown">
          <transition name="fade">
            <ul v-if="listOne" @click="listOne = false" class="dropdown-menu">
              <li class="menu-item">
                <router-link to="/streaming" class="custom-underline"
                  >Streaming</router-link
                >
              </li>
              <li class="menu-item">
                <router-link to="/" class="custom-underline"
                  >Development</router-link
                >
              </li>
              <li class="menu-item">
                <router-link to="/" class="custom-underline"
                  >Agency</router-link
                >
              </li>
            </ul>
          </transition>
        </div>
      </div>
      <div class="router-link">
        <router-link to="/project" class="router-hover scrollactive-item"
          >Projects</router-link
        >
      </div>
      <div class="router-link">
        <router-link to="/candidates" class="router-hover scrollactive-item">
          Candidates</router-link
        >
      </div>
      <div class="router-link">
        <router-link to="/contact" class="router-hover scrollactive-item"
          >Contact Us</router-link
        >
      </div>
      <div class="router-link">
        <Languages />
      </div>
    </div>
    <div @click.self="showNav" class="navbar" id="nav">
      <div class="nav">
        <Navbar @close="closeNav" />
      </div>
    </div>
  </div>
  <!-- <router-view :key="$route.params" /> -->
</template>

<script>
import Logo from "../components/includes/header/Logo.vue";
import Languages from "../components/includes/header/Languages.vue";
import Navbar from "../components/includes/header/Navbar.vue";
export default {
  name: "Header",
  components: {
    Logo: Logo,
    Languages: Languages,
    Navbar: Navbar,
  },
  // scrooolll

  created() {
    window.addEventListener("resize", this.onScreenResize);
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      listOne: false,
      view: {
        topOfPage: true,
      },
      computedStyle: null,
      screenWidth: null,
    };
  },
  methods: {
    //  onItemChanged(event, currentItem, lastActiveItem) {
    // your logic
    // },
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    showNav(e) {
      this.computedStyle = window.getComputedStyle(e.target.firstChild);
      this.computedStyle = this.computedStyle.getPropertyValue("display");
      if (this.computedStyle === "none") {
        e.target.firstChild.style.display = "block";
      }
    },
    onScreenResize() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth >= 900) {
        document.getElementById("nav").firstChild.style.display = "none";
      }
    },
    closeNav() {
      document.getElementById("nav").firstChild.style.display = "none";
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent linear-gradient(180deg, #eaffff 0%, #eaffff00 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  min-height: 120px;
  z-index: 30;
  width: calc(100% - 140px);
  @media (max-width: 900px) {
    width: calc(100% - 60px);
  }
  &.onScroll {
    box-shadow: 0 0 10px #aaa;
    background-color: #fff;
    transition: all ease-in-out 0.5s;
    height: 20px;
    min-height: 70px;
    /* transition: all .8s ease; */
  }
  .header-component {
    display: flex;
    margin: 40px 0;
    .active-section {
      text-decoration: underline 3px solid #b9c0ff;
      text-decoration-style: double;
    }
    .router-link {
      color: map-get($font_colors, "main");
      text-align: right;
      font-weight: 500;
      padding-left: 15px;
      letter-spacing: 0px;
      position: relative;
      .router-hover:hover {
        color: map-get($font_colors, "secondary");
      }
      .dropdown {
        display: block;
        background-color: map-get($colors, "white");
        position: absolute;
        top: 30px;
        left: 0;
        color: map-get($font_colors, "main");
        font-weight: 500;
        border-radius: 2px;

        .dropdown-menu {
          padding: 10px 0;
          margin: 0 15px;
          list-style-type: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 7px;
          .menu-item {
            .custom-underline {
              position: relative;
              display: inline-block;
            }
            .custom-underline::after {
              content: "";
              position: absolute;
              top: 85%;
              height: 2px !important;
              width: 0%;
              left: 0;
              background-color: rgba(73, 16, 16, 0.6);
              transition: 0.4s ease-out all 0.1s;
            }

            &:hover .custom-underline::after {
              width: 100%;
              /* left: 5%; */
              transition: 0.5s ease all;
            }
          }
        }
      }
    }
    &.elements {
      @media (max-width: 900px) {
        display: none;
      }
    }
  }
  .navbar {
    display: none;
    @media (max-width: 900px) {
      display: inline-block;
    }
    z-index: 4;
    content: "";
    background-image: url("../assets/icons/hamburger.svg");
    cursor: pointer;
    background-repeat: repeat-x;
    background-size: cover;
    width: 30px;
    height: 25px;
    background-position: center;
    .nav {
      cursor: initial;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      min-width: 100vw;
      min-height: 100vh;
      width: 100%;
      height: auto;
      background-color: map-get($colors, "white");
      display: none;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all ease-in-out 0.5s;
    opacity: 1;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
}
</style>