import { createRouter, createWebHistory } from 'vue-router';
import Home from "../views/Home.vue";
import AboutUs from "../views/AboutUs.vue";
import Streaming from "../views/Streaming.vue";
import Candidates from "../views/Candidates.vue";
import ContactUs from "../views/ContactUs.vue";

const routes = [
   {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/candidates",
    name: "Candidates",
    component: Candidates,
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/streaming",
    name: "Streaming",
    component: Streaming,
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
