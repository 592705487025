<template>
  <div class="nav-header padding-container margin-container-top">
    <div class="nav-logo">
      <div class="logo">
        <Logo />
      </div>
      <img class="close-x" @click="$emit('close')" src= "../../../assets/icons/close.svg" />
    </div>
    <div class="nav-color">
      <div class="nav-el font-size-xl">
        <div class="router-link">
          <router-link to="/" class="router-hover" >Home</router-link>
        </div>
        <div class="router-link">
          <router-link to="/"  class="router-hover"> About Us</router-link>
        </div>
        <div class="router-link">
          <router-link to="/" class="router-hover" > Services</router-link>
        </div>
        <div class="router-link">
          <router-link  to="/" class="router-hover">Projects</router-link>
        </div>
        <div class="router-link">
          <router-link to="/" class="router-hover">Contacts Us</router-link>
        </div>
        <div class="router-link">
          <router-link to="/" class="router-hover" >Candidates</router-link>
        </div>
      </div>
      <div class="color"></div>
    </div>
    <div class="nav-footer font-size-md">
     <div class="social">
      <router-link to="/" class="routers">Facebook</router-link>
      <router-link to="/" class="routers">Instagram</router-link>
      <router-link to="/" class="routers">LinkedIn</router-link>
     </div>
     <div class="lang">
     <Languages class="top-drop" />
     </div>
    </div>
  </div>
</template>
<script>
import Logo from "../../includes/header/Logo.vue";
import Languages from "../../includes/header/Languages.vue";
export default {
  name: "Navbar",
  components: {
    Logo: Logo,
    Languages: Languages,
  },
};
</script>
<style lang="scss" scoped>
.nav-header {
  z-index: 4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: auto;
  height: 100%;
  overflow-x: hidden;
  .nav-logo {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    justify-content: space-between;
    align-items: center;
    .close-x {
     // background-image: url("../../../assets/icons/close.svg");
      cursor: pointer;
     // background-size: cover;
     // width: 30px;
     // height: 25px;
     // background-position: center;
      /* width: 25px;
    height: 24px; */
    }
  }
  .nav-color {
    display: flex;
    flex-direction: row;
    position: relative;
    .nav-el {
      display: flex;
      flex-direction: column;
     color: map-get($font_colors, "main");
      text-align: left;
      font-weight: 500;
      gap: 20px;
    }
    .color {
      position: absolute;
      top: 0;
      right: 0;
      //bottom: 0;
      float: right;
      margin-right: -50px;
      width: 40%;
      height: 370px;
      border-radius: 50%;
      background: transparent linear-gradient(280deg, #EDEFFF 0%, #636ED4 100%)
        0% 0% no-repeat padding-box;
      opacity: 0.7;
      filter: blur(50px);
      z-index: 4;
    }
  }
  .nav-footer {
    z-index: 4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .social{
      display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 60px;
    .routers {
     color: map-get($font_colors, "main");
      font-weight: 700;
    }
  }
  .lang{
padding-bottom: 60px;
  }
  }
}
</style>