<template>
  <div class="contact">
    <background :title="$t('pages.contactUs.Title')">
      <div class="bottom-scroll">
        <ArrowDown />
      </div>
    </background>
    <div class="contact-text padding-container">
      <div class="description-holder padding-container-remover">
        <div class="title">{{ $t("pages.contactUs.title") }}</div>
        <div class="description">
          {{ $t("pages.contactUs.description") }}
        </div>
      </div>
      <div class="contacts padding-container-remover">
        <div class="directions font-size-md">
          <div class="company">
            {{ $t("pages.contactUs.company") }}
          </div>
          <div class="street">
            {{ $t("pages.contactUs.street") }}
          </div>
          <div class="location">
            {{ $t("pages.contactUs.location") }}
          </div>
        </div>
        <div class="info font-size-md">
          <div class="tel">Tel: +355 123 456 789 0</div>
          <div class="mob">Mobile: +355 123 456 789 0</div>
          <div class="email">Email: hello@streamup.com</div>
        </div>
        <button class="btn">
          <a
            target="_blank"
            href="https://www.google.com/maps/@41.3256371,19.814592,21z"
            >{{ $t("pages.contactUs.button.direction") }}</a
          >
        </button>
      </div>
    </div>
    <footer-vue><contact-card></contact-card></footer-vue>
  </div>
</template>

<script>
import Background from "../components/Background.vue";
import FooterVue from '../components/FooterVue.vue';
import ArrowDown from "../components/includes/views/ArrowDown.vue";
import ContactCard from "../components/includes/footer/footer-includes/ContactCard.vue";
export default {
  name: "ContactUs",
  components: {
    Background,
    ArrowDown,
    FooterVue,
    ContactCard,
  },
};
</script>

<style lang="scss" scoped>
.contact {
  .bottom-scroll {
    z-index: 25;
    cursor: pointer;
    // padding-bottom: 50px;
  }
  .contact-text {
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 10%;
    row-gap: 50px;
    text-align: start;
    word-break: break-word;
    @media (max-width: 900px) {
      grid-template-columns: auto;
      text-align: center;
    }
    .description-holder {
      text-align: start;

      @media (max-width: 900px) {
        text-align: center;
      }
      .title {
        color: map-get($font_colors, "main");
        font-weight: 500;
      }
      .description {
        max-width: 80%;
        text-align: start;

        @media (max-width: 900px) {
          text-align: center;
          max-width: 100%;
        }
        padding-top: 5%;
        color: map-get($font_colors, "secondary");
        font-weight: 700;
      }
    }
    .contacts {
      display: flex;
      flex-direction: column;
      text-align: start;
      @media (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      row-gap: 30px;
      .directions {
        color: map-get($font_colors, "main");
        font-weight: 700;
      }
      .info {
        color: map-get($font_colors, "secondary");
        font-weight: 700;
      }
      a {
        color: map-get($font_colors, "button-color");
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    /* column-gap: 20px; */
    align-items: center;
    cursor: pointer;
    background-color: map-get($colors, "blue");
    border: none;
    color: map-get($font_colors, "button-color");
    font-weight: 500;
    padding: 20px 50px;
    width: 50%;
    @media (max-width: 400px) {
      padding: 10px 25px;
    }
    @media (max-width: 200px) {
      padding: 0;
    }
  }
  z-index: 25;
  padding-bottom: 100px;
  @media (max-width: 900px) {
    padding-bottom: 0;
  }
  /* &.padding {
    padding-bottom: 35px;
  } */
}
</style>