<template>
    <div>
 <h2 class="a font-size-md">prohe</h2>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.a{
   color: red;
}
</style>