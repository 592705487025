import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/includes/i18n'
import VueScrollactive from 'vue-scrollactive';
 


createApp(App).use(i18n).use(router).use(store).use(VueScrollactive).mount('#app')

