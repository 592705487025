<template>
  <div class="service padding-container">
    <div class="title font-size-xl">Our Services</div>
    <div class="subTitle font-size-big">From streaming, to agency and on-site development as a client, to project management</div>
    <div class="service-info">
      <router-link to="/streaming" class="router-link">
        <div class="service-card">
          <div class="service-image">
            <img src="../../../../assets/icons/online-streaming.svg" alt="" />
          </div>
          <div class="services">
            <div class="service-title font-size-big">Streaming</div>
            <div class="service-desc font-size-sm">Streaming</div>
          </div>
        </div>
      </router-link>
      <router-link to="/services" class="router-link">
        <div class="service-card">
          <div class="service-image">
            <img
              src="../../../../assets/icons/software-development.svg"
              alt=""
            />
          </div>
          <div class="services">
            <div class="service-title font-size-big">Development</div>
            <div class="service-desc font-size-sm">Development</div>
          </div>
        </div>
      </router-link>
      <router-link to="/services" class="router-link">
        <div class="service-card">
          <div class="service-image">
            <img src="../../../../assets/icons/outsourcing.svg" alt="" />
          </div>
          <div class="services">
            <div class="service-title font-size-big">Agency</div>
            <div class="service-desc font-size-sm">Agency</div>
          </div>
        </div>
      </router-link>
    </div>
    <!-- <router-link to="/services" class="want-more">
      <div class="want font-size-md">Want to see more?</div>
      <div class="icon"></div>
    </router-link> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  background: transparent
    linear-gradient(
      360deg,
      map-get($colors, "primary") 0%,
      map-get($colors, "linear-gradient-second") 100%
    )
    0% 0% no-repeat padding-box;
    padding: 50px 0px;
    .title{
      color: map-get($font_colors , "main" );
      font-weight: 600;
      padding: 20px 0px;
      /* font-family: monospace; */
    }
     .subTitle{
      color: map-get($font_colors , "secondary" );
      font-weight: 500;
      padding-bottom: 40px;
      width: 68%;
    }
  .service-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .router-link {
      /* display: flex;
      flex-direction: column; */
      flex-grow: 1;
      width: 100%;
      height: 100%;
      .service-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
        /* padding: 30px 0px; */
        /* width: 400px; */
        transition: transform .7s ease-in-out;
        height: 300px;
        overflow: hidden;
        @media (max-width: 900px) {
          height: 200px;
        }
        &:hover {
          /* box-shadow: 10px 10px 10px 10px #aaa; */
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          transform: scale(1.03);
          overflow: hidden;
        }
        .services {
          .service-title {
            color: map-get($font_colors, "main");
            font-weight: 600;
          }
          .service-desc {
            color: map-get($font_colors, "secondary");
            font-weight: 500;
          }
        }
        .service-image {
          /* background-image: url("../../../../assets/icons/outsourcing.svg"); */
          max-width: 50%;
          /* align-self: flex-end; */
          /* max-width: 100%; */
          padding: 0;
          img {
            width: 100px;
            object-fit: cover;
          }
        }
      }
    }
  }
  .want-more{
    display: flex;
    flex-direction: row;
    padding: 40px 0px;
    justify-content: center;
    align-items: center;
    .want{
    color: map-get($font_colors , "main" );
    font-weight: 500;
    }
    .icon{
      background-image: url("../../../../assets/icons/next.svg");
      width: 40px;
      height: 40px;
      margin-left:  25px;
      background-repeat: no-repeat;
    }
  }
}
</style>