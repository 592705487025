<template>
  <div class="home">
    <background  :title="$t('pages.home.title')"
      :text="$t('pages.home.text')" ></background>
    <info></info>
    <service-card></service-card>
    <main-body>
      <div class="title-project font-size-xl">Our Projects</div>
      <div class="project-description font-size-big">
        {{ $t("pages.home.description") }}
      </div>
      <div class="project">
        <div class="col1" >
          <project-card
            v-for="(post, index) in firstPart"
            :key="index"
            :class="post.color"
            :title="post.title"
            :subTitle="post.sub_title"
            :image="post.photo.preview"
          >
          </project-card>
        </div>
        <div class="col2">
          <project-card
            v-for="(post, index) in secondPart"
            :key="index"
            :class="post.color"
            :title="post.title"
            :subTitle="post.sub_title"
            :image="post.photo.preview"
          >
          </project-card
        ></div>
        <div class="col3">
          <project-card
            v-for="(post, index) in thirdPart"
            :key="index"
            :class="post.color"
            :title="post.title"
            :subTitle="post.sub_title"
            :image="post.photo.preview"
          >
          </project-card
        ></div>
      </div>
      <!-- <div class="view-button">
        <router-link @click="scrolling" to="/projects">
          <div class="button font-size-default flex">
            <button class="home-button" v-bind="$attrs">
              {{ $t("pages.home.project-card.btn") }}
            </button>
          </div>
        </router-link>
      </div> -->
    </main-body>
    <partner />
    <footer-vue>
      <card class="margin-container">
        <home-card></home-card>
      </card>
    </footer-vue>
  </div>
</template>

<script>
// @ is an alias to /src

import Background from "@/components/Background.vue";
import Info from "@/components/includes/pages/home/Info.vue";
import ServiceCard from "@/components/includes/pages/services/ServiceCard.vue";
import MainBody from "../components/includes/views/MainBody.vue";
// import ContentWrapper from "../components/includes/views/ContentWrapper.vue";
import ProjectCard from "../components/includes/pages/projects/ProjectCard.vue";
import axios from "axios";
import PostSplitMixin from "../mixins/PostSlit";
import Partner from "../components/includes/pages/home/Partner.vue";
import Card from "../components/includes/footer/Card.vue";
import FooterVue from "../components/FooterVue.vue";
import HomeCard from "../components/includes/footer/footer-includes/HomeCard.vue";

export default {
  name: "Home",
  components: {
    Background,
    Info,
    ServiceCard,
    MainBody,
    // ContentWrapper,
    ProjectCard,
    Partner,
    Card,
    FooterVue,
    HomeCard,
  },
  mixins: [PostSplitMixin],
  data() {
    return {
      color: "blue",
      ombre: null,
      acquaMarine: null,
      title: null,
      text: null,
      subTitle: null,
      image: null,
      firstPart: null,
      secondPart: null,
      thirdPart: null,
      counter: 3,
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    getRandomIntInclusive(min, max) {
      min = Math.ceil(1);
      max = Math.floor(15);
      return (this.text = Math.floor(Math.random() * (max - min + 1) + min)); //The maximum is inclusive and the minimum is inclusive
    },
    postGet() {
      axios
        .get( process.env.VUE_APP_BASE_URL +
            "/posts?type=project&category_name=" + "&paginate=3",
          {
            headers: { "Content-Language": this.$i18n.locale },
          }
        )
        .then((response) => {
          // this.even = response.data.data.filter(this.evenPosts);
          // this.even = this.even.filter(this.colorizefirstColumn);
          // this.odd = response.data.data.filter(this.oddPosts);
          // this.odd = this.odd.filter(this.colorizesecondColumn);
          this.firstPart = response.data.data.filter(this.firstSplit);
          this.firstPart = this.firstPart.filter(this.colorizefirstColumn);
          this.secondPart = response.data.data.filter(this.secondSplit);
          this.secondPart = this.secondPart.filter(this.colorizesecondColumn);
          this.thirdPart = response.data.data.filter(this.thirdSplit);
          this.thirdPart = this.thirdPart.filter(this.colorizeThirdColumn);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    firstSplit(value, key, arr) {
      if (key % 3 == 0) {
        return arr;
      }
    },
    secondSplit(value, key, arr) {
      if (key % 3 == 1) {
        return arr;
      }
    },
    thirdSplit(value, key, arr) {
      if (key % 3 == 2) {
        return arr;
      }
    },
    scrolling() {
      window.scrolling = true;
    },
  },

  computed: {},

  watch: {
    getPosts: {
      immediate: true,
      handler() {
        this.postGet();
      },
    },
    "$i18n.locale": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.postGet();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  .project-description {
    padding: 40px 0;
    max-width: 75%;
    color: map-get($font_colors, "main");
    word-break: break-word;
    text-align: start;
    font-weight: 500;
    &::before {
      content: "";
      float: right;
      width: 8em;
      height: 1em;
    }
  }
  .title-project {
    color: map-get($font_colors, "main");
    font-weight: 600;
    /* padding: 20px 0px; */
  }
      .project {
      overflow-x: hidden;
      padding-top: 100px;
      padding-bottom: 50px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-auto-rows: 1fr;
      gap: 40px;
      height: 100%;

      @media (max-width: 900px) {
        grid-template-columns: auto;
      }
      @mixin column {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        row-gap: 40px;
        @media (max-width: 900px) {
          width: 100%;
        }
        flex: 1;
      }
      .col1 {
        @include column;
      }
      .col2 {
        position: relative;
        bottom: -40px;

        @include column;
        @media (max-width: 900px) {
          position: static;
        }
      }
      .col3 {
        @include column;
      }
    }
  .view-button {
    padding-top: 100px;
    .button {
      // position: absolute;
      // bottom: 10%;
      // left: 0;
      // right: 0;
      &.flex {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .home-button {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        align-items: center;
        cursor: pointer;
        background-color: map-get($colors, "blue");
        border: none;
        color: map-get($font_colors, "button-color");
        font-weight: 500;
        padding: 20px 50px;
        @media (max-width: 400px) {
          padding: 10px 25px;
        }
        @media (max-width: 200px) {
          padding: 0;
        }
      }
      z-index: 25;
      padding-bottom: 100px;
      @media (max-width: 900px) {
        padding-bottom: 0;
      }
      &.padding {
        padding-bottom: 35px;
      }
    }
  }
}
</style>